<template>
  <div class="scanner-cont container">
    <h1 class="text-center p-2">{{ "فحص المعدة" | translate }}</h1>
    <hr />
    <div v-if="ready" class="scanner">
      <!-- <qr-reader
        class="camera"
        @code-scanned="onDecode"
        @error-captured="errorCaptured"
        :stop-on-scanned="true"
      /> -->
      <qr-reader
        @code-scanned="onDecode"
        @error-captured="errorCaptured"
        :stop-on-scanned="true"
      >
        <div class="loading-indicator" v-if="loading">Loading...</div>
      </qr-reader>
    </div>
    <p style="text-align: center !important" class="text-center">
      {{ "قم بتوجيه الكاميرا وفحص الكود والانتقال لتفاصيل المعدة" | translate }}
    </p>
  </div>
</template>
<script>
import QrReader from "vue-qr-reader/dist/lib/vue-qr-reader.umd.js";
// import Loading from "../components/loading";
export default {
  created() {},
  mounted() {
    const scanner = document.getElementsByClassName("vue-qr-reader__container")[0];
    scanner.classList.remove("vue-qr-reader__container");
  },
  components: {
    QrReader,
    // Loading,
  },
  data() {
    return {
      audio: "",
      ready: true,
      insertedId: null,
      loading: false,
    };
  },
  computed: {},
  methods: {
    async onDecode(e) {
      // if (!this.ready) return;
      // this.studentInfo = {};
      // this.groupsInfo = [];
      const beep1 = document.getElementById("beep1");
      beep1.play();
      this.$router.push("/followup/" + e);
      // const beep2 = document.getElementById("beep2");
      // const beep3 = document.getElementById("beep3");
    },
    ////////////////////////////////////////////

    /////////////////////////////////////////////

    errorCaptured(error) {
      let errorMessage = null;
      switch (error.name) {
        case "NotAllowedError":
          errorMessage = "Camera permission denied.";
          break;
        case "NotFoundError":
          errorMessage = "There is no connected camera.";
          break;
        case "NotSupportedError":
          errorMessage = "Seems like this page is served in non-secure context.";
          break;
        case "NotReadableError":
          errorMessage = "Couldn't access your camera. Is it already in use?";
          break;
        case "OverconstrainedError":
          errorMessage = "Constraints don't match any installed camera.";
          break;
        default:
          errorMessage = "UNKNOWN ERROR: " + error.message;
      }
      console.error(errorMessage);
      this.$store.dispatch("writeMessage", errorMessage ? errorMessage : error);
    },
  },
};
</script>
<style lang="scss">
h2 {
  color: #fff;
}
.scanner-cont {
  // width: 100vw;
  .scanner {
    // width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e0e3e5b4;
    padding: 2px;
    .camera {
      // width: 80vw;
    }
  }
}
</style>
